
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    const heightToVisible = 20;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
      {isVisible && (
        <div
          className="top-btn"
          onClick={goToTop}
          style={{
            fontSize: '2.4rem',
            width: '6rem',
            height: '6rem',
            color: '#fff',
            backgroundColor: 'none', 
            boxShadow: 'none', 
            borderRadius: '50%',
            position: 'fixed',
            bottom: '2rem',
            right: '0.1rem',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            animation: 'gototop 1.2s linear infinite alternate-reverse',
            
          }}
        >
          <Image src="./images/moon.png" alt="^" style={{width:"40%"}}/>
        </div>
      )}
      <style>
        {`
          @keyframes gototop {
            0% {
              transform: translateY(-0.5rem);
            }
            100% {
              transform: translateY(1rem);
            }
          }
        `}
      </style>
    </div>
  );
};

export default ScrollButton;
