import React, { useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";

export const ContactMe = () => {
  // const buttonStyle = {
  //   boxShadow: "0px 12px 24px -1px #000000",
  //   background: "linear-gradient(to bottom, #44c767 5%, #5cbf2a 100%)",
  //   backgroundColor: "#44c767",
  //   borderRadius: "19px",
  //   border: "3px solid #c6c908",
  //   display: "inline-block",
  //   cursor: "pointer",
  //   color: "#fff0f9",
  //   fontFamily: "Arial",
  //   fontSize: "18px",
  //   fontWeight: "bold",
  //   padding: "6px 22px",
  //   textDecoration: "none",
  //   textShadow: "-1px 5px 3px #080808",
  // };
    const inputBg = {
        backgroundColor: '#FFE3BB'
    ,color:"#1640D6",
    
    fontSize:"18px",
    fontFamily:"pacifico"
}


const [formData, setFormData] = useState({
  name: '',
  email: '',
  message: '',
});

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};

const handleSubmit = async (e) => {
  e.preventDefault();

  // Use Formspree endpoint as the 'action' attribute of the form
  const formUrl = 'https://formspree.io/f/xyyqrwng';

  try {
    const response = await fetch(formUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      // console.log('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        message: '',
      })
      toast.success('Thank you 😊, I will get back to you as soon as possible', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      // console.error('Form submission failed.');
      toast.error("Ops! Something went wrong, Try again !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  } catch (error) {
    // console.error('Error submitting form:', error);
  }
}


  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center mb-5 "
    >
      <Container className="text-center mt-5 mb-5">
        <h2  style={{marginTop:"1rem",fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold" ,fontSize:"3rem"}}>Contact me </h2>
        <h5 className="mt-3" style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold" }}>Feel free to Contact me by submitting the form below and I will get back to you as soon as possible</h5>
      </Container>
      <Container >
        <Col md={{ span: 6, offset: 3 }}>
          {/* <Form  style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold" }}>
            <Form.Group className="mb-3" controlId="ControlText" >
              <Form.Label >Name</Form.Label>
              <Form.Control type="text" placeholder="Name" style={inputBg} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Email" style={inputBg} />
              <Form.Text className="text-muted">
               <i> We'll never share your email with anyone else.</i>
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Your Message 😊"  style={inputBg}/>
            </Form.Group>
            <Container className="text-center">
            <Button href="#" className="text-center" type="submit" style={buttonStyle} >
              Submit
            </Button>
            </Container>
          </Form> */}
              <Form style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="ControlText">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Name"
          style={inputBg}
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          style={inputBg}
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <Form.Text className="text-muted">
          <i>We'll never share your email with anyone else.</i>
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3" controlId="ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Your Message 😊"
          style={inputBg}
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </Form.Group>
      <Container className="text-center">
        <Button variant="primary" className="text-center" type="submit" >
          Submit
        </Button>
      </Container>
    </Form>
        </Col>
      </Container>
    </Container>
  );
};

