import React from 'react'
import ContainerSpace from '../../utils/ContainerSpace'
import { Container } from 'react-bootstrap'
import { customNavbarStyle, keyframes } from '../../utils/background'
import { Footer } from '../pageComponent/Footer'
import Project from "../pageComponent/Project"


export const ProjectPage = () => {
  return (
    <>
    <ContainerSpace/>
    <Container  className="navbar" fluid style={customNavbarStyle }>
      <style>{keyframes}</style>
      <Container style={{marginBottom:"4rem"}}>
    </Container>
    <Container  >
    <Project/>
    </Container>
    
    <Container style={{marginBottom:"8rem"}}>
    </Container>
    <Footer/>
    </Container>
</>
  )
}
