import { Col, Container, Row } from "react-bootstrap";

function Skill() {
  return (
    <Container fluid className=" mt-5 mb-5 pb-5"  style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold"}}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col lg={8} className="mt-3">
          <h1 className="text-center mx-sm-5 " style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold" ,fontSize:"3rem"}}>Skills</h1>
          <p>
          Embarking on the Digital Frontier, I navigate with proficiency across a spectrum of 
          Programming Languages, mastering the syntax and logic of Java, Python, 
          and JavaScript. My expertise extends to Database Management, where I seamlessly
           handle SQL, ensuring data integrity and efficiency. With adept Version 
           Control using Git and GitHub, I ensure collaborative harmony in project development.
            Additionally, my proficiency in Web Development includes working with technologies 
            such as React.js, Bootstrap, HTML, and CSS, crafting dynamic and visually engaging 
            online experiences for a captivating digital journey.
          </p>
          <Container fluid className="text-center mt-5">
            <Row className="d-flex justify-content-center mb-2">
              <Col className="mx-2 border ">Java ✩✩✩✩</Col>
              <Col className="mx-2 border ">JavaScript ✩✩✩</Col>
              <Col className="mx-2 border ">Python ✩✩✩</Col>
            </Row>
            <Row className="d-flex justify-content-center mb-2">
              <Col className="mx-2 border ">Spring boot ✩✩✩✩</Col>
              <Col className="mx-2 border ">React js ✩✩✩✩</Col>
              <Col className="mx-2 border ">Bootstrap ✩✩✩✩</Col>
            </Row>
            <Row className="d-flex justify-content-center mb-2">
              <Col className="mx-2 border ">Sql ✩✩✩✩</Col>
              <Col className="mx-2 border ">Git/Github ✩✩✩</Col>
              <Col className="mx-2 border ">MongoDb ✩✩</Col>
            </Row>
            <Row className="d-flex justify-content-center mb-2">
              <Col className="mx-2 border ">Html ✩✩✩✩</Col>
              <Col className="mx-2 border ">Css ✩✩</Col>
              <Col className="mx-2 border ">Linux ✩✩</Col>
            </Row>
            <Row className="d-flex justify-content-center mb-2">
              <Col className="mx-2 border ">Web Development ✩✩✩✩</Col>
              <Col className="mx-2 border ">Api Development ✩✩✩✩</Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default Skill;
