import { useMotionValueEvent, useScroll, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// ... (existing imports)

function MyNavbar() {
  const customNavbarStyle = {
    background: "linear-gradient(47deg, #9a933a, #a74f35, #a74f35)",
    backgroundSize: "800% 800%",
    animation: "backgrond-color 25s ease infinite",
  };

  const keyframes = `
    @keyframes backgrond-color {
      0% {
        background-position: 0% 26%;
      }
      50% {
        background-position: 100% 75%;
      }
      100% {
        background-position: 0% 26%;
      }
    }
  `;

  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  // const scroll = (elementId) => {
  //   scrollToElement(elementId);
  // };
  const isLargeScreen = useMediaQuery({ minWidth: 335 });

  // retracting toggle menu
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);

  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };

  const handleNavbarClose = () => {
    setExpanded(false);
  };

  const handleOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target) && expanded) {
      handleNavbarClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('scroll', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [expanded]);
  return (
    <motion.nav
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: "-100" },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.25, ease: "easeInOut" }}
    >
      <Navbar
        expand="lg"
        className="bg-body-tertiary "
        fixed="top"
        expanded={expanded}
        style={customNavbarStyle}
        ref={navbarRef}
      >
        <style>{keyframes}</style>
        <Container style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold"}}>
          <Navbar.Brand >
            <Link
              onClick={() =>{handleNavbarClose(); }}
              as={NavLink} to="/"
              style={{ color: "black", textDecoration: "none" }}
            >
              {isLargeScreen ? <h2 style={{ fontFamily: "Black Ops One", fontSize: "2rem" }}>
                Prem Prakash
              </h2> : <h2 style={{ fontFamily: "Black Ops One", fontSize: "2rem" }}>
                Prem 
              </h2>}

            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleNavbarToggle}  />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="me-auto"></Nav>
            <Nav className="justify-content-end">
              <Nav.Link as={NavLink}  onClick={() =>{handleNavbarClose()}} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} onClick={() =>{handleNavbarClose()}} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={NavLink} onClick={() =>{handleNavbarClose()}} to="/thought">Thought</Nav.Link>
              <Nav.Link as={NavLink} onClick={() =>{handleNavbarClose()}} to="/projects">Project</Nav.Link>
              <Nav.Link as={NavLink} onClick={() =>{handleNavbarClose()}} to="/contact-me">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </motion.nav>
  );
}

export default MyNavbar;
