

import { Container } from "react-bootstrap"
import ContainerSpace from "../../utils/ContainerSpace"
import About from "../pageComponent/About"
import { Footer } from "../pageComponent/Footer"
import { customNavbarStyle, keyframes } from "../../utils/background"
function AboutPage() {
  return (
    <>
        <ContainerSpace/>
        <Container  className="navbar" fluid style={customNavbarStyle }>
          <style>{keyframes}</style>
        <About/>
        <Container style={{marginBottom:"8rem"}}>
        </Container>
        <Footer/>
        </Container>
    </>
  )
}

export default AboutPage