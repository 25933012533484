import { Container } from "react-bootstrap"
import ContainerSpace from "../../utils/ContainerSpace"
import { customNavbarStyle, keyframes } from "../../utils/background"
import { Footer } from "../pageComponent/Footer"
import { ContactMe } from "../pageComponent/ContactMe"



export const ContactPage = () => {
  return (
    <>
    <ContainerSpace/>
    <Container  className="navbar" fluid style={customNavbarStyle }>
      <style>{keyframes}</style>
      <Container style={{marginBottom:"4rem"}}>
    </Container>
    <ContactMe/>
    <Container style={{marginBottom:"8rem"}}>
      
    </Container>
    <Footer/>
    </Container>
</>
  )
}
