import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import projectData from "../../assets/projectData.json";
import { motion} from "framer-motion";


function Project() {

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(projectData);
  }, []);

  return (
    <>
    <Container fluid className="d-flex align-items-center justify-content-center text-center"  >
            <h1 style={{marginTop:"4rem",fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold" ,fontSize:"4rem"}} >Projects</h1> 
    </Container>
    <Container
      fluid
      className=" align-items-center  text-center mt-5" style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold"}}
    >
        
      <Row className="mb-4 mx-3">
      {data && (
        
        <Row className="d-flex align-items-center justify-content-center text-center"  >
          {data.map((item, index) => (
            <Col key={index} md={{ span: 4 }} className="mb-5 mt-2" >
              <Card className="d-flex align-items-center justify-content-center text-center "
               style={{  background: 'transparent', border: 'none'}}>
              <a href={item.link}
               target="_blank"
               rel="noopener noreferrer" style={{ color: "#232D3F", textDecoration: "none" }}>
                <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} >
                <Card.Img variant="top" src={item.imageUrl} style={{border: "4px double #6DB9EF",objectFit: 'cover',overflow:"hidden"}}/>
                </motion.div>
                <Card.Body>
                  <Card.Title><h2><b>{item.name}</b></h2></Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body></a>
              </Card>
            </Col>
            
          ))}
        </Row>
       
        )}
      </Row>

     

      
    </Container>
    </>
  );
}

export default Project;
