export const customNavbarStyle = {
    background: 'linear-gradient(47deg, #35806c, #9a933a, #a74f35, #9d27a5)',
    backgroundSize: '800% 800%',
    animation: 'backgrond-color 10s ease infinite',
  };

 export const keyframes = `
    @keyframes backgrond-color {
      0% {
        background-position: 0% 26%;
      }
      50% {
        background-position: 100% 75%;
      }
      100% {
        background-position: 0% 26%;
      }
    }
  `;