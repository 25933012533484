import React, { useEffect, useState } from "react";
import { Card, Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { Footer } from "../pageComponent/Footer";
import { customNavbarStyle, keyframes } from "../../utils/background";
import brokenImage from "../../assets/broken-image2.png";
import certificateData from "../../assets/certificates.json";
import ContainerSpace from "../../utils/ContainerSpace";

export const ThoughtPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(certificateData);
  }, []);

//   console.log(data);

  return (
    <><ContainerSpace/>
    <Container className="navbar pt-5" fluid style={customNavbarStyle}>
      <style>{keyframes}</style>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center text-center"
        style={{ fontFamily: "Montserrat  ,sans-serif", fontWeight: "bold" }}
      >
        <Row className="d-flex justify-content-center align-items-center text-center mt-2">
          <Col md={{ span: 12 }}>
            <h2 className="mt-5">
              <b>Certificates & Achievement</b>
            </h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center  mt-5">
          <Col sm={{ span: 8 }} fluid>
            <Carousel fade>
              {data &&
                data.map((item, index) => (
                  <Carousel.Item id={index} interval={5000}>
                    <Card
                      style={{
                        width: "80%",
                        // borderTopLeftRadius: "45%",
                        // borderTopRightRadius: "45%",
                        overflow: "hidden",
                        marginLeft: "10%",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <Card.Header
                        style={{
                          border: "none",
                          width: "66%",
                          marginLeft: "17%",
                        }}
                        as="h4"
                      >
                       <b> {item.name}</b>
                      </Card.Header>
                      <Card.Body>
                        <Card.Title style={{color:'black'}}> <b><i>{item.title} </i></b></Card.Title>
                        <Image
                          src={item.image || {brokenImage}}
                          rounded
                          fluid
                          alt="image not found"
                          style={{maxHeight:"350px",minHeight:"auto",overflow: "hidden"}}
                        />
                        <Card.Text className="mb-5 mt-4"> {item.desc}</Card.Text>
                        <Card.Text></Card.Text>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginBottom: "8rem" }}></Container>
      <Footer />
    </Container>
    </>
  );
};

{
  /* <Carousel.Item>
<Card
    style={{
      width: "80%",
      // borderTopLeftRadius: "45%",
      // borderTopRightRadius: "45%",
      overflow: "hidden",
      marginLeft: "10%",
    }}
  >
    <Card.Header as="h5">Featured</Card.Header>
    <Card.Body>
      <Card.Title>Special title treatment</Card.Title>
      <Image className="width-80%" src={profile} rounded fluid />
      <Card.Text>
        With supporting text below as a natural lead-in to
        additional content.
      </Card.Text>
    </Card.Body>
  </Card>

</Carousel.Item> */
}
