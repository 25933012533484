import { motion, useTransform, useScroll } from "framer-motion";
import Project from "../pageComponent/Project";
import { Container } from "react-bootstrap";

export const ZoomInProject = () => {
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [0.2, 1]);

  return (
    <Container className="wrapper d-flex align-items-center justify-content-center">
      <motion.div
        className="container"
        style={{
          scale
        }}
      >
        <motion.div
          className="item"
          style={{
            scaleY: scrollYProgress
          }}
        />
        <Project/>
      </motion.div>
    </Container>
  );
};
