import React from "react";
import { Card, Col, Container, Row, Image } from "react-bootstrap";
import profile from "../../assets/profile-pic.png";

function About() {
  return (
    <>
      <Container
        fluid
        className="d-flex align-items-center mt-5 mb-2 justify-content-center text-center"
        id="about-section"
      >
        <h3 style={{ fontFamily: "Pacifico", color: "#DFB361" ,marginTop:"2rem",textShadow: '8px 6px 4px rgba(0, 0, 0, 0.5)'}}>
          I'm Prem Prakash
        </h3>
      </Container>

      <Container
        fluid
        className="d-flex align-items-center justify-content-center mx-5"
        style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold"}}
      >
        <Row>
          <Col lg={3} sm={4} md={3} className="d-flex align-items-center justify-content-center " >
            <Card
              style={{
                width: "80%",
                borderTopLeftRadius: "45%",
                borderTopRightRadius: "45%",
                overflow: "hidden",
              }}
            >
              <Image className="width-80%" src={profile} rounded fluid />
            </Card>
          </Col>
          <Col lg={8} sm={8} md={8}>
            <h4 className="py-4">
            <b>Architecting Tomorrow's Digital Landscape: </b>
            Seamless Frontend Elegance, Robust Backend 
            Brilliance – Your Fullstack Visionary in Code!
            </h4>
            <p>
            Embark on a digital journey where innovation knows no bounds.
             As your dedicated Fullstack Developer, I bring forth a
              harmonious blend of frontend finesse and backend robustness.
               With a passion for crafting seamless user experiences and 
               ensuring the backbone of your applications stands resilient,
                I am committed to transforming your visions into dynamic, 
                functional realities. Let's code a future where possibilities
             are endless, and user satisfaction is of utmost importance.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5">
        <Col
          className="border shadow rounded col-5 sm-col-8 xs-12 margin-left-auto d-flex justify-content-between mb-2"
          style={{
            marginLeft: "auto",
            border: "1px solid #9a933a",
            marginRight: "8rem",
          }}
        >
          <span
            className="m-2"
            style={{ fontFamily: "Pacifico", color: "#39A7FF" }}
          >
            Java
          </span>{" "}
          <span
            className="align-self-end m-2"
            style={{ fontFamily: "Pacifico", color: "#BED754" }}
          >
            9-month
          </span>
        </Col>
        <Col
          className="border shadow rounded col-7 d-flex justify-content-between mb-2"
          style={{
            marginLeft: "auto",
            border: "1px solid #9a933a",
            marginRight: "4rem",
          }}
        >
          <span
            className="m-2 fontFamily-Pacifico"
            style={{ fontFamily: "Pacifico", color: "#39A7FF" }}
          >
            Spring Boot
          </span>{" "}
          <span
            className="align-self-end m-2"
            style={{ fontFamily: "Pacifico", color: "#BED754" }}
          >
            6-month
          </span>
        </Col>
        <Col
          className="border shadow rounded col-5 d-flex justify-content-between mb-2"
          style={{
            marginRight: "auto",
            border: "1px solid #9a933a",
            marginLeft: "8rem",
          }}
        >
          <span
            className="m-2"
            style={{ fontFamily: "Pacifico", color: "#39A7FF" }}
          >
            React
          </span>{" "}
          <span
            className="m-2"
            style={{ fontFamily: "Pacifico", color: "#BED754" }}
          >
            6-month
          </span>
        </Col>
        <Col
          className="border shadow rounded col-8 d-flex justify-content-between mb-2"
          style={{
            marginRight: "auto",
            border: "1px solid #9a933a",
            marginLeft: "3rem",
          }}
        >
          <span
            className="m-2"
            style={{ fontFamily: "Pacifico", color: "#39A7FF" }}
          >
            JavaScript
          </span>
          <span
            className="align-self-end m-2"
            style={{ fontFamily: "Pacifico", color: "#BED754" }}
          >
            4-month
          </span>
        </Col>
      </Container>
      <Container >
        
          <Row >
            <Col sm={{ span: 8, offset: 2 }}fluid>
              <Card
                className="my-2 shadow p-4"
                style={{
                  position: "relative",
                  top: 100,
                  background:"#c77056"
                }}
              >
                
                  <h5 style={{fontFamily:"roboto mono", fontSize:"1.5rem",
                    background:"#c77056",
                }}>
                    Before diving into the world of programming, my professional
                    background was in zoology and education. I decided to
                    explore programming during the COVID lockdown. Starting with
                    Python, I spent a year learning and working on small
                    projects through Coursera. Later, I joined a bootcamp, where
                    I learned about Java full-stack development. This experience
                    expanded my skill set to include Java, SQL, Spring, Spring
                    Boot, JavaScript, and React. During the bootcamp, I actively
                    contributed to various projects, gaining practical insights
                    into the development process. Since then, my interest in
                    technology has driven me to continue exploring and
                    navigating the ever-changing world of programming.
                  </h5>
                
              </Card>
            </Col>
          </Row>
      </Container>
    </>
  );
}

export default About;
