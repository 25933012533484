import React from "react";
import { Col, Container, Row ,Image} from "react-bootstrap";
import twitter from "../../assets/twitter.png";
import instagram from "../../assets/instagram.png";
import linkedin from "../../assets/linkedin.png";
import github from "../../assets/github.png";
import email from "../../assets/email.png";
import { scrollToElement } from "../../utils/ScrollToElemet";
import { Link } from "react-router-dom";
import ScrollButoon from "../pageComponent/ScrollButoon"



export const Footer = () => {
  const emailAddress = 'premascii@gmail.com';
  const scroll = (elementId) => {
    scrollToElement(elementId);
  };
  return (
    <Container fluid style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold" }}>
      
      <Container>
        <hr style={{ width: "100%", borderTop: "2px solid #ccc" }} />

        <Row className="mt-5 margin-left-auto d-flex justify-content-between">
          <Col className=" col-sm-6" md={4}>
            <Link
              onClick={() => scroll("top-page")}
              style={{ color: "black", textDecoration: "none" }}
            >
              <h3 className="mx-2"> <b>Prem Prakash </b></h3>
            </Link>
            <p className="mx-2">Architecting Tomorrow's Digital Landscape</p>
          </Col>

          <Col className=" d-flex justify-content-end  " md={4}>
            <span>
              <h3 className="mx-2"> <b>Social</b></h3>
              <div>
                <a
                  href={`mailto:${encodeURIComponent(emailAddress)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-1"
                    style={{ width: "2.5rem" }}
                    src={email}
                    alt="Email"
                  />
                </a>
                <a
                  href="https://github.com/eklavya-byte"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-1"
                    style={{ width: "2.5rem" }}
                    src={github}
                    alt="GitHub"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/prem-prakash-b72140252/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-1"
                    style={{ width: "2.5rem" }}
                    src={linkedin}
                    alt="LinkedIn"
                  />
                </a>
                <a
                  href="https://twitter.com/Prakash_zz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-1"
                    style={{ width: "2.5rem" }}
                    src={twitter}
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://www.instagram.com/prem_likes_sanity/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-1"
                    style={{ width: "2.5rem" }}
                    src={instagram}
                    alt="Instagram"
                  />
                </a>
              </div>
            </span>
          </Col>
        </Row>
        <hr style={{ width: "100%", borderTop: "2px solid #ccc" }} />
      </Container>
      <hr style={{ width: "100%", borderTop: "2px solid #ccc" }} />
      <ScrollButoon/>
      <Container className="d-flex align-items-center  mb-2 justify-content-center text-center">
        <h6>
         <b> © 2023. Made by <span>Prem Prakash</span></b>
        </h6>
      </Container>
    </Container>
  );
};
