import { Container } from "react-bootstrap"
import { FirstPage } from "../pageComponent/FirstPage"
import Skill from "../pageComponent/Skill"
import About from "../pageComponent/About"
import { ZoomInProject } from "../framerMotion/ZoomInProject"
import { Footer } from "../pageComponent/Footer"
import { ContactMe } from "../pageComponent/ContactMe"
import ContainerSpace from "../../utils/ContainerSpace"
import { customNavbarStyle, keyframes } from "../../utils/background"

export const MyHome = () => {


  return (

<Container  className="navbar" fluid style={customNavbarStyle }>
          <style>{keyframes}</style>
          <ContainerSpace/>
          <FirstPage/>
          <About/>
          <ZoomInProject/> 
          <Skill/>
          <ContactMe/>
          <Footer/>
        </Container>

  )
}
