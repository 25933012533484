import React, { useState } from "react";
import { Button, Card, Col, Container, Image } from "react-bootstrap";
import profile from "../../assets/Zrvb7y01.svg";
import { motion} from "framer-motion";
import { scrollToElement } from "../../utils/ScrollToElemet";
import { useMediaQuery } from "react-responsive";


export const FirstPage = () => {
  const [isHovered, setIsHovered] = useState(false);
  const scrollDown = (elementId) => {
    scrollToElement(elementId);
  };
  const isLargeScreen = useMediaQuery({ minWidth: 769 });
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center"
      style={{ marginTop:"10rem" ,marginBottom:"10rem" }}
      
    >
      <Col lg={4} md={6} className="text-center mx-3 mx-md-5 ">
        <h3 >
          <span style={{fontFamily:'Pacifico',textShadow: '8px 6px 10px rgba(220,200,100, 0.5)'}}>Hey, I'm Prem Prakash </span><span style={{fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold"}}>– your go-to Fullstack Developer. I specialize
          in Spring Boot for robust backends and React for sleek front-end
          interfaces. Let's turn concepts into code and create digital magic !</span>
        </h3>
        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} >
        <Button
          className="mt-4"
          variant="primary" onClick={()=>scrollDown('about-section')}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{background:"#3876BF",fontFamily: 'Montserrat  ,sans-serif',fontWeight:"bold",textShadow: '6px 8px 4px rgba(0,0,0, 0.5)'}}
         
        >
          About me{" "}
          <span style={{ display: isHovered ? "inline" : "none" }}>↓</span>
          <span style={{ display: isHovered ? "none" : "inline" }}>→</span>
        </Button>
        </motion.div>
      </Col>
      <Col xs={12} md={4} className="mb-4 mt-4">
      <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} >
        <Card style={{ width: "50%",background:'transparent',border:'none',marginLeft:"25%" }}>

        {isLargeScreen && <Image src={profile} rounded fluid />}
          
         
        </Card>
        </motion.div>
      </Col>
    </Container>
  );
};
