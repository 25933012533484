import {  Route, Routes } from "react-router-dom";
import { MyHome } from "./components/page/MyHome";
import MyNavbar from "./components/pageComponent/MyNavbar";
import AboutPage from "./components/page/AboutPage";
import { ContactPage } from "./components/page/ContactPage";
import { ProjectPage } from "./components/page/ProjectPage";
import { ThoughtPage } from "./components/page/ThoughtPage";


function App() {
  const customNavbarStyle = {
    background: 'linear-gradient(47deg, #35806c, #9a933a, #a74f35, #9d27a5)',
    backgroundSize: '800% 800%',
    animation: 'backgrond-color 10s ease infinite',
  };

  const keyframes = `
    @keyframes backgrond-color {
      0% {
        background-position: 0% 26%;
      }
      50% {
        background-position: 100% 75%;
      }
      100% {
        background-position: 0% 26%;
      }
    }
  `;
  return (
    <>
    <MyNavbar />
    <Routes>
      <Route path="/" element={ <MyHome/>} />
      <Route path="/about" element={ <AboutPage/>} />
      <Route path="/contact-me" element={ <ContactPage/>} />
      <Route path="/projects" element={ <ProjectPage/>} />
      <Route path="/thought" element={ <ThoughtPage/>} />
    </Routes>
    </>
  );
}

export default App;
