import React from 'react'
import { Container } from 'react-bootstrap'

function ContainerSpace() {
  return (
    <Container fluid className='mt-2' id="top-page">
        
    </Container>
  )
}

export default ContainerSpace